// Angular-Module
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {NgForm} from '@angular/forms';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
// Globale Services einbinden
import {StorageService} from '@global/services/storage.service';
// Modules Services einbinden
import {SalesService} from '@modules/sales/sales.service';
// Shared Services einbinden
import {InputDateService} from '@shared/input/input-date/input-date.service';
// Interfaces für Structured Objects einbinden
import {FormInput} from '@shared/form-input';
import {LooseObject} from '@shared/loose-object';
// Moment.js
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'phscw-popup-formular',
    templateUrl: './popup-formular.component.html',
    styleUrls: ['./popup-formular.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupFormularComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Referenz auf Form
    @ViewChild('popupForm', {static: false}) popupForm: NgForm;

    // Konfiguration der Eingabefelder
    formConfiguration: FormInput[] = [];
    // Daten der Eingabefelder
    formData: LooseObject = {};

    // Flag definiert ob gerade geladen wird
    loadingProductsData = false;

    /*
     * Daten für die Produktauswahl
     * Zeigt die Anzahl der ProduktLevel an
     */
    maxProductLevels = 3;
    // Select-Data für die Produkt-Selectboxen
    productSelectionData: [] = [];

    // Spezifisches selectData für product
    productSelections = {};

    // ERster Eintrag der Produktselektion soll imer total sein
    totalSelectDataEntry = [
        {
            id: 0,
            label: 'Total',
        },
    ];

    chosenProducts: number[] = [];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param data
     * @param changeDetector
     * @param dialogRef
     * @param storageService
     * @param salesService
     * @param inputDateService
     */
    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: LooseObject,
        private changeDetector: ChangeDetectorRef,
        private dialogRef: MatDialogRef<PopupFormularComponent>,
        private storageService: StorageService,
        private salesService: SalesService,
        private inputDateService: InputDateService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Versehentliches Schließen verhindern
        this.dialogRef.disableClose = true;

        // Formular-Daten verarbeiten
        this.initializeFormData();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Übergeben Daten für das Formular verarbeiten
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private initializeFormData(): void {
        // Abbrechen, falls keine Daten vorhanden sind
        if (
            !(
                Object.prototype.hasOwnProperty.call(this.data, 'data') &&
                this.data.data !== null &&
                typeof this.data.data !== 'undefined'
            ) &&
            this.data.data.length > 0
        ) {
            return;
        }

        // Formular-Daten zwischenspeichern
        this.formConfiguration = JSON.parse(JSON.stringify(this.data.data));

        // Produktdaten aus dem Speicher laden, falls die Produktauswahl verwendet wird
        const productsDataRequired = this.formConfiguration.some(
            (configuration: FormInput) => configuration.field_type === 'products',
        );
        if (productsDataRequired) {
            this.getProductsData();
        }

        // Felder für ngModel initialisieren
        this.formConfiguration.forEach((input: FormInput) => {
            // Für Jahr und Monats Input die alternativen Felder befüllen
            if (input.field_type === 'date') {
                this.formData[input.field_id + '_input'] = moment(input.field_value);
            } else if (input.field_type == 'dateRange') {
                const start = input?.field_value?.start;
                const end = input?.field_value?.end;

                if (typeof start === 'string') {
                    this.formData[input.field_id + '_start'] = moment(start);
                }
                if (typeof end === 'string') {
                    this.formData[input.field_id + '_end'] = moment(end);
                }
            } else if (input.field_type === 'year') {
                this.formData[input.field_id + '_input'] = moment(input.field_value);
                this.onYearChange(input.field_id);
            } else if (input.field_type === 'month') {
                this.formData[input.field_id + '_input'] = moment(input.field_value);
                this.onMonthChange(input.field_id);
            } else if (input.field_type === 'autocomplete') {
                this.onAutoCompleteSelectionChanged(input.field_id, {
                    id: input.field_value,
                    label: input.field_value_label,
                });
            } else if (
                input.field_type === 'products' &&
                input.field_value !== null &&
                Array.isArray(input.field_value)
            ) {
                this.chosenProducts = input.field_value;
            } else if (input.field_type === 'numberRange') {
                // Achtung: funktioniert nur mit CRUD filter
                if (input.field_value && typeof input.field_value === 'object') {
                    this.formData[`${input.field_id} >=`] =
                        typeof input.field_value.start === 'number' ? input.field_value.start : undefined;
                    this.formData[`${input.field_id} <=`] =
                        typeof input.field_value.end === 'number' ? input.field_value.end : undefined;
                } else {
                    this.formData[`${input.field_id} >=`] = undefined;
                    this.formData[`${input.field_id} <=`] = undefined;
                }
            } else {
                this.formData[input.field_id] = input.field_value;
            }
        });

        // Daten zurücksetzen
        delete this.data.data;

        // Change-Detection manuell auslösen
        this.changeDetector.detectChanges();
    }

    /**
     * @brief   Button im Dialog wurde angeklickt, Antwort merken und Dialog schließen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private getProductsData(): void {
        // Produktdaten laden aus dem Storage, oder aus dem Backend
        this.storageService.getItem('salesProducts').then((result: []) => {
            if (result !== null) {
                this.productSelectionData = result;

                // Initiales Befüllen der SelectionData
                this.editProductSelectionData();
            } else {
                console.error('salesProducts no init; timing problem?');
            }

            // Change-Detection manuell auslösen
            this.changeDetector.detectChanges();
        });
        this.storageService.getItem('salesMaxProductLevel').then((result: number) => {
            if (result !== null) {
                this.maxProductLevels = result;
            }

            // Change-Detection manuell auslösen
            this.changeDetector.detectChanges();
        });
    }

    /**
     * @param answer
     * @brief   Button im Dialog wurde angeklickt, Antwort merken und Dialog schließen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickAndClose(answer: string): void {
        // Init
        let responseData: LooseObject = {};

        // Antwort prüfen
        if (answer === 'yes') {
            // Daten aus Formular auslesen
            responseData = this.popupForm.form.value;

            // Datumsfelder formatieren
            responseData = this.formatDateFields(responseData);

            responseData = this.formatNumberFields(responseData);
        }

        // geklickte Antwort übernehmen
        responseData.answer = answer;

        // Dialog (über Referenz) schließen und Dialog-Ergebnis mitsenden
        this.dialogRef.close(responseData);
    }

    /**
     * @brief   Alle Felder leeren
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    reset() {
        this.popupForm.form.reset();
    }

    /**
     * Numerische Felder formatieren für das Backend
     * und konvertierung, damit es ins Datenbank-Format für Prozentzahlen passt: aktuell nur opportunities.probability
     * @param {LooseObject} data Inputs
     * @returns {LooseObject} Konvertierte Inputs
     */
    private formatNumberFields(data: LooseObject): LooseObject {
        this.formConfiguration.forEach((field: FormInput) => {
            if (field.field_type === 'numberRange') {
                const startKey = `${field.field_id} >=`; // Workaround: vergleich ist im key drin
                const endKey = `${field.field_id} <=`;
                const startValue = data[startKey];
                const endValue = data[endKey];

                // Startwert setzen und ggf. konvertieren fürs DB-Format bei Prozentzahlen
                if (typeof startValue === 'number') {
                    data[startKey] = field.field_is_percentage ? startValue / 100 : startValue;
                } else {
                    delete data[startKey];
                }

                // Endwert setzen und ggf. konvertieren fürs DB-Format bei Prozentzahlen
                if (typeof endValue === 'number') {
                    data[endKey] = field.field_is_percentage ? endValue / 100 : endValue;
                } else {
                    delete data[endKey];
                }
            }
        });

        return data;
    }

    /**
     * @param data
     * @brief   Datum formatieren für Backend
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private formatDateFields(data: LooseObject): LooseObject {
        // Über Konfiguration laufen und Felder des Typs Datum finden
        this.formConfiguration.forEach((field: FormInput) => {
            if (field.field_type == 'dateRange' && (data[field.field_id + '_start'] || data[field.field_id + '_end'])) {
                const start = this.inputDateService.getDateValueForSave(
                    this.popupForm.form.value[field.field_id + '_start'],
                    'YYYY-MM-DD',
                    true,
                );
                const end = this.inputDateService.getDateValueForSave(
                    this.popupForm.form.value[field.field_id + '_end'],
                    'YYYY-MM-DD',
                    true,
                );
                data[field.field_id] = {
                    start,
                    end,
                };
                delete data[field.field_id + '_start'];
                delete data[field.field_id + '_end'];
            }
            // nicht ausgefüllte Felder überspringen
            if (field.field_type !== 'date' || data[field.field_id] === undefined) {
                return;
            }

            // Datum für Backend formatieren
            data[field.field_id] = this.inputDateService.getDateValueForSave(
                this.popupForm.form.value[field.field_id],
                'YYYY-MM-DD',
            );
        });

        // Daten zurückgeben
        return data;
    }

    /**
     * @param attributeName
     * @param event
     * @brief   Auf Klick einer Checkbox reagieren
     * @details Benutzt ECMAScript 2018 object spread zum Zusammenführen der Daten zweier Objekte
     * @see     https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Operators/Spread_syntax
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onCheckboxChange(attributeName: string, event: LooseObject): void {
        // Zusammenführen der bestehenden Auswahl mit der neuen Auswahl
        this.formData[attributeName] = {
            ...this.formData[attributeName],
            ...event,
        };
    }

    /**
     * @param attributeName
     * @brief   Auf Klick eines Monats reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onMonthChange(attributeName: string): void {
        // Zusammenführen der bestehenden Auswahl mit der neuen Auswahl
        this.formData[attributeName] = this.formData[attributeName + '_input'].month();
    }

    /**
     * @param attributeName
     * @brief   Auf Klick eines Jahres reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onYearChange(attributeName: string): void {
        // Zusammenführen der bestehenden Auswahl mit der neuen Auswahl
        this.formData[attributeName] = this.formData[attributeName + '_input'].year();
    }

    /**
     * @param attributeName
     * @param value
     * @brief   Auf Klick einer Option reagieren
     * @author  Julian Reitermann <j.reitermann@pharmakon.software>
     */
    onAutoCompleteSelectionChanged(attributeName: string, value: LooseObject): void {
        // Zusammenführen der bestehenden Auswahl mit der neuen Auswahl
        this.formData[attributeName] = value.id;
        this.formData[attributeName + '_value_label'] = value.label;
    }

    /**
     * @param attributeName
     * @param value
     * @brief   Auf Löschen eines Autocomplete Eingabefelds reagieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onAutoCompleteSelectionDeleted(attributeName: string, value: LooseObject): void {
        // Zusammenführen der bestehenden Auswahl mit der neuen Auswahl
        this.formData[attributeName] = null;
        this.formData[attributeName + '_value_label'] = null;
    }

    /**
     * Prüfen welche Selectboxen für die Produkt-Auswahl angezeigt werden sollen
     * (Wie viele Level von Produktdaten angezeigt werden sollen)
     * @details Übernommen aus der Umsatzanalyse
     * @param currentLevel
     * @param   number   currentLevel
     * @returns  boolean
     * @author  Michael Schiffner <m.schiffner@pharmakon.software>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    checkConditions(currentLevel: number): boolean {
        return this.productSelections[currentLevel] != undefined && this.productSelections[currentLevel].length > 1;
    }

    /**
     * Holt die Kinder-Select-Daten für ein bestimmtes Produkt.
     * @details Übernommen aus der Umsatzanalyse
     * @author  Michael Schiffner <m.schiffner@pharmakon.software>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    editProductSelectionData() {
        for (const currentLevel of Array(this.maxProductLevels).keys()) {
            if (currentLevel == 0) {
                this.productSelections[currentLevel] = this.totalSelectDataEntry.concat(this.productSelectionData);
            } else if (currentLevel > 0) {
                const toBeFoundId = this.chosenProducts[currentLevel - 1];
                const productObject = this.productSelections[currentLevel - 1].find(
                    (product) => product.id == toBeFoundId,
                );

                if (productObject && 'children' + currentLevel in productObject) {
                    this.productSelections[currentLevel] = this.totalSelectDataEntry.concat(
                        productObject['children' + currentLevel],
                    );
                } else {
                    this.productSelections[currentLevel] = [];
                }
            }
        }
    }

    /**
     * Geht sicher, dass, wenn sich die Produkte ändern, gewählte Produkte auf höheren Leveln zurückgesetzt werden.
     * @param event
     * @param level
     * @details Übernommen aus der Umsatzanalyse
     * @author  Michael Schiffner <m.schiffner@pharmakon.software>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    productsChanged(event: any, level: number): void {
        // wenn ein Produkt geändert wird sollen alle SelectionData aktualsiert werden
        this.editProductSelectionData();

        // Muss nur das nächstobere Produkt zurücksetzten. Produkte, die darüber liegen werden von der Template-logik automatisch ausgeblendet und müssen nicht beachtet werden.
        this.chosenProducts[level] = 0;

        // Change-Detection manuell auslösen
        this.changeDetector.detectChanges();
    }
}
