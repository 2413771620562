// Importieren der notwendigen Angular-Komponenten und -Module
import {
    Component,
    OnInit,
    ViewChild,
    Input,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {TableColumn} from '@shared/table-column';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SelectionModel} from '@angular/cdk/collections';
import {TableVirtualScrollDataSource} from 'ng-table-virtual-scroll';
import {GridNewOptions} from '../grid-new-options';
import {detailExpandAnimation} from './animations/expand';
import {GridNewService} from './shared/grid-new.service';
import {
    COLUMN_CURRENCY,
    COLUMN_DATE,
    COLUMN_DECIMAL,
    COLUMN_ICON,
    COLUMN_INTEGER,
    COLUMN_LISTENTRY,
    COLUMN_PERCENTAGE,
    COLUMN_TAG,
} from './shared/grid-new-modes';
import {GridEvent, GridEventType} from './shared/grid-new-event-types';
import {StorageService} from '@global/services/storage.service';
import {Listentry} from '@shared/listentry';
import {SelectData} from '@shared/select-data';
import {InitService} from '@global/services/init.service';
import {DefaultReactiveComponent} from '@shared/default-reactive/default-reactive.component';
import {isArray} from '@shared/type-guards';
import {Identifiable} from '@shared/types';
import {GlobalSearchService} from '@global/components/global-search/global-search.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarDefaultComponent} from '@shared/snackbars/snackbar-default/snackbar-default.component';
import {TranslateService} from '@ngx-translate/core';
import {FilterData} from '@shared/filter-data';

@Component({
    selector: 'phscw-grid-new',
    templateUrl: './grid-new.component.html',
    styleUrls: ['./grid-new.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [detailExpandAnimation],
})
export class GridNewComponent<T extends Identifiable>
    extends DefaultReactiveComponent
    implements OnInit, AfterViewInit, OnDestroy {
    /*
     * Name der Entität, um das korrekte Schema für die Spaltenkonfigurationen zu laden
     * z.B. "Opportunities", "Contacts", "Institutions", etc.
     *
     * In der Backend-Config muss ebenfalls ein Key mit diesem Namen existieren (innerhalb von "newGridDisplayColumns")
     *
     * TODO: unbedingt verbessern, kann nicht so bleiben
     */
    @Input({required: true}) entityName: string | null = null;

    /*
     * Name der anzuzeigenden Spalten. Diese werden in der Backend-Config definiert.
     * Pfad für Backend-Config innerhalb von "newGridDisplayColumns".
     *
     * TODO: verbessern
     */
    @Input({required: true}) displayedColumnsName: string | null = null;

    /*
     * Spaltenkonfiguration
     * Wird dynamisch anhand vom "entityName" und "displayedColumnsName" gesetzt
     */
    gridColumns: TableColumn<T>[] = [];

    /*
     * Spalten, die angezeigt werden sollen
     * Wird dynamisch anhand vom "displayedColumnsName" gesetzt
     */
    set displayedColumns(value: string[]) {
        this.#originalDisplayedColumns = [...value]; // Klonen, um Mutation zu vermeiden
        this.updateDisplayedColumns();
    }

    get displayedColumns(): string[] {
        return this.#displayedColumns;
    }

    // Tatsächlich angezeigte Spalten, eventuell inkl. Checkbox-Spalte
    #displayedColumns: string[] = [];

    // Eindeutiger Name des Grids
    @Input({required: true}) gridName: string;

    // Backend-Endpunkt, mit dem die Daten geladen werden
    @Input() gridDataBackendUrl: string;

    // TODO: für später zum Erzeugen der Router-Links
    @Input() routerBasePath: string | null = null;

    /**
     * Optionale Eingabe zur Überschreibung der Datenquelle des Grids.
     * Wenn diese Eigenschaft gesetzt ist, wird die Datenquelle direkt verwendet und kein Backend-Request ausgeführt.
     *
     * Sinnvoll, wenn man die benötigten Daten z.B. durch ein contain in den "Hauptdaten" bereits geladen hat
     * Beispiel: Opportunities-Daten enthalten alle zugehörigen Kontakte. Somit kann dem Grid einfach "opportunityData.contacts" übergeben werden,
     *           um diese Daten woanders anzuzeigen
     */
    @Input()
    set overrideDataSource(value: T[] | null) {
        this.#overrideDataSource = value;
        this.initializeData();
    }

    get overrideDataSource(): T[] | null {
        return this.#overrideDataSource;
    }

    #overrideDataSource: T[] | null = null;

    // Flag zur Steuerung der Anzeige der Checkbox-Spalte
    @Input()
    set enableCheckboxSelection(value: boolean) {
        this.#enableCheckboxSelection = value;
        this.updateDisplayedColumns();
    }

    get enableCheckboxSelection(): boolean {
        return this.#enableCheckboxSelection;
    }

    // Ursprüngliche angezeigte Spalten ohne die Checkbox-Spalte
    #originalDisplayedColumns: string[] = [];
    // Checkbox-Spalte sichtbar?
    #enableCheckboxSelection = true;

    // Regionsfilter Daten
    @Input() set gridRegionsFilter(value) {
        this.#gridRegionsFilter = value;
        this.resetAndReloadGrid();
    }

    get gridRegionsFilter() {
        return this.#gridRegionsFilter;
    }

    #gridRegionsFilter: object = {};

    /*
     * Filter
     *
     * Die übergebenen Filterdaten müssen mit den Spaltennamen der Tabelle übereinstimmen
     * Die Filterung wird im CRUD zusammengesetzt: "addFilterToQuery"
     *
     * TODO: evtl. auch machbar ohne erneutes Laden der Daten mit direkter Filterung der Datenquelle
     */
    #gridFilter: FilterData;
    get gridFilter() {
        return this.#gridFilter;
    }

    @Input() set gridFilter(value) {
        // Wert übernehmen
        this.#gridFilter = value;

        // Grid resetten und ggf. neu laden
        this.resetAndReloadGrid();
    }

    /*
     * Skeleton-Daten für die Ladeanimation
     * TODO: verbessern
     */
    skeletonData = Array(5).fill({});

    // Variable zum Speichern der aktuell erweiterten Zeile
    expandedElement: T | null;

    // Variable zum Speichern der Selektion
    checkboxSelection = new SelectionModel<T>(true, []);

    searchActive: boolean = false;

    /*
     * Flag, ob Daten geladen werden
     * Wird unter anderem benutzt, um die Ladeanimation anzeigen zu lassen
     */
    loading: boolean = false;

    /**
     * Setter für die Sortierung
     * Wenn die Sortierung des Grids initialisiert wird, wird sie der Datenquelle zugewiesen
     */
    @ViewChild(MatSort) set sort(ms: MatSort) {
        if (this.virtualDataSource) {
            this.virtualDataSource.sort = ms;
        }
    }

    virtualDataSource = new TableVirtualScrollDataSource<T>();

    /*
     * Anzuzeigenden Spalten
     * Fügt die Checkbox-Selection hinzu, falls enableCheckboxSelection true ist
     */
    get columnsToDisplay(): string[] {
        return this.enableCheckboxSelection ? ['grid-select', ...this.displayedColumns] : this.displayedColumns;
    }

    /*
     * Listentries, die zur Darstellung benötigt werden.
     * Um nicht mehr mit jedem input-select-Aufruf die Listentries neu zu laden
     */
    public lists: {[key: string]: SelectData[]} = {};

    // Listentries, die zur Formatierung von Zahlen benötigt werden
    private numberFormatTypes: Listentry[] = [];

    initialized = false;

    // Typeguard, für Zugriff im Template
    public isArray = isArray;

    protected readonly COLUMN_CURRENCY = COLUMN_CURRENCY;
    protected readonly COLUMN_DATE = COLUMN_DATE;
    protected readonly COLUMN_LISTENTRY = COLUMN_LISTENTRY;
    protected readonly COLUMN_ICON = COLUMN_ICON;
    protected readonly COLUMN_TAG = COLUMN_TAG;
    protected readonly COLUMN_INTEGER = COLUMN_INTEGER;
    protected readonly COLUMN_DECIMAL = COLUMN_DECIMAL;
    protected readonly COLUMN_PERCENTAGE = COLUMN_PERCENTAGE;

    // Map zum Speichern von id zu Index für schnellen Zugriff beim Updaten
    private idToIndexMap: Map<number | string, number> = new Map();

    constructor(
        private router: Router,
        private gridNewService: GridNewService<T>,
        private cdr: ChangeDetectorRef,
        private storageService: StorageService,
        private initService: InitService,
        private globalSearchService: GlobalSearchService,
        private snackBar: MatSnackBar,
        private translateService: TranslateService,
    ) {
        super();
    }

    ngOnDestroy(): void {
        // eslint-disable-next-line no-console
        console.log('grid-new destroyed');
    }

    ngOnInit(): void {
        Promise.all([
            this.gridNewService.loadConfigFromScheme(this.entityName).then((columns) => {
                this.gridColumns = columns;
            }),
            this.gridNewService.loadDisplayedColumns(this.displayedColumnsName).then((columns) => {
                this.displayedColumns = columns;
            }),
        ]).then(() => {
            this.initializeEventSubscriptions();
            this.initializeSortingDataAccessor();

            if (this.initService.initialized) {
                // Initialisierungen die abhängig davon sind, ob der InitService alles fertig geladen hat
                this.onAllInitialized();
            } else {
                this.subscribeUntilDestroyed(this.initService.allInitialized, (initialized: boolean) => {
                    if (initialized) {
                        this.onAllInitialized();
                    }
                });
            }
        });
    }

    private initializeEventSubscriptions() {
        this.listenForGridEvents();

        /*
         * Suche in der Tabelle ohne Backend-Request
         * TODO: später für beide Möglichkeiten erweitern
         */
        this.subscribeUntilDestroyed(this.globalSearchService.searchInput$, (searchInput: string) => {
            this.applyFilter(searchInput);
        });
    }

    /**
     * Listener für Grid-Events
     * TODO: kommentare
     */
    private listenForGridEvents(): void {
        this.subscribeUntilDestroyed(this.gridNewService.events$, (event: GridEvent<T>) => {
            /*
             * TODO: prüfung auf event.target hier machen?
             * es wird fälle geben wo das nicht zutrifft(?)
             */
            switch (event.type) {
                case GridEventType.DataChanged:
                    if (event.target !== this.gridName) {
                        return;
                    }
                    if (Array.isArray(event.entity)) {
                        this.updateEntitiesInDataSource(event.entity);
                    } else {
                        this.updateEntityInDataSource(event.entity as T);
                    }
                    break;
                case GridEventType.DataDeleted:
                    if (event.target !== this.gridName) {
                        return;
                    }
                    this.handleDataDeleted(event);
                    break;
                case GridEventType.ClearCheckboxSelection:
                    if (event.target !== this.gridName) {
                        return;
                    }
                    this.clearCheckboxSelection();
                    break;
                default:
                    break;
            }
        });
    }

    /**
     * @template T
     * Überprüft, ob die übergebene Entität Kontakt-bezogene Eigenschaften besitzt
     * Falls ja, bereitet sie die Kontakt-Daten entsprechend auf
     * Falls nein, wird die Entität unverändert zurückgegeben
     * @param {T} entity - Die zu prüfende Entität
     * @returns {T} - Die ggf. angepasste Entität
     */
    private prepareContactIfNeeded(entity: T): T {
        // Prüfen, ob Kontakt-spezifische Felder vorhanden sind
        if (entity && ('contact_start_date' in entity || 'contact_end_date' in entity)) {
            return this.prepareContactDates(entity);
        }

        return entity;
    }

    /**
     * @template T
     * Aktualisiert oder fügt mehrere Entitäten der Datenquelle hinzu.
     * Falls eine Entität bereits existiert, wird sie aktualisiert.
     * Falls nicht, wird sie hinzugefügt.
     * Nach Verarbeitung aller Entitäten wird die Datenquelle einmal neu gesetzt,
     * um die Änderungen sichtbar zu machen.
     * @param {T[]} entities - Ein Array von zu verarbeitenden Entitäten.
     */
    private updateEntitiesInDataSource(entities: T[]): void {
    // Durchlaufen aller übergebenen Entitäten
        for (const entity of entities) {
            try {
            // Jede Entität verarbeiten
                this.updateOrAddSingleEntity(entity);
            } catch (error) {
                console.error('Error updating/adding entity:', entity, error);
            }
        }

        // Am Ende Datenquelle neu setzen, um Änderungen anzuzeigen
        this.virtualDataSource.data = [...this.virtualDataSource.data];
        this.cdr.markForCheck();
    }

    /**
     * @template T
     * Aktualisiert oder fügt eine einzelne Entität der Datenquelle hinzu.
     * @param {T} entity - Die zu aktualisierende oder hinzuzufügende Entität.
     */
    private updateOrAddSingleEntity(entity: T): void {
        // Kontakt ggf. vorbereiten
        const preparedEntity = this.prepareContactIfNeeded(entity);

        // Index in der Map suchen
        const index = this.idToIndexMap.get(preparedEntity.id);

        if (index !== undefined) {
        // Entität aktualisieren
            this.virtualDataSource.data[index] = preparedEntity;
        } else {
        // Entität nicht gefunden, hinzufügen
            this.addEntityToDataSource(preparedEntity);
        }
    }

    /**
     * @template T
     * Aktualisiert oder fügt eine einzelne Entität der Datenquelle hinzu.
     * @param {T} updatedEntity - Die zu aktualisierende oder hinzuzufügende Entität.
     */
    updateEntityInDataSource(updatedEntity: T): void {
        // Kontakt ggf. vorbereiten
        const preparedUpdatedEntity = this.prepareContactIfNeeded(updatedEntity);

        const index = this.idToIndexMap.get(preparedUpdatedEntity.id);

        if (index !== undefined) {
            // Entität aktualisieren
            this.virtualDataSource.data[index] = preparedUpdatedEntity;
        } else {
            // Entität hinzufügen, falls sie nicht existiert
            this.addEntityToDataSource(preparedUpdatedEntity);
        }

        // Datenquelle neu setzen und Check für Change Detection
        this.virtualDataSource.data = [...this.virtualDataSource.data];
        this.cdr.markForCheck();
    }

    /**
     * Entfernt die im Event enthaltenen Entitäten aus der Datenquelle.
     * @param {GridEvent} event - GridEvent
     */
    private handleDataDeleted(event: GridEvent<T>) {
        if (event.target !== this.gridName) {
            return;
        }

        // Extrahiert die zu löschenden Entitäten aus dem Event
        const entitiesToDelete = this.extractEntitiesToDelete(event);

        if (entitiesToDelete.length > 0) {
            // Entität(en) aus dem Grid entfernen
            this.removeEntitiesFromDataSource(entitiesToDelete);

            // Snackbar anzeigen
            const snackbarLabel = this.translateService.instant('GENERAL.DELETESUCCESSFUL');
            this.showSnackbar(snackbarLabel);

            // Checkboxen zurücksetzen
            this.clearCheckboxSelection();
            this.cdr.markForCheck();
        }
    }

    /**
     * Extrahiert die zu löschenden Entitäten aus dem Event.
     * @template T
     * @param {GridEvent} event - Das Grid-Event, das die zu löschenden Entitäten enthält.
     * @returns {T[]} Ein Array der zu löschenden Entitäten.
     */
    private extractEntitiesToDelete(event: GridEvent<T>): T[] {
        if (event.checkboxSelection && event.checkboxSelection.length > 0) {
            return event.checkboxSelection;
        }

        return [];
    }

    /**
     * Initialisiert den sortingDataAccessor der Datenquelle
     * Bestimmt, nach welchem Wert die Spalte sortiert werden soll
     *
     * Wenn eine Spalte eine 'sortProperty' hat, wird nach diesem Wert sortiert.
     * Wenn keine Spalte eine 'sortProperty' hat, wird nach dem Wert in 'columnDef' sortiert.
     * @see TODO link doku
     */
    private initializeSortingDataAccessor(): void {
        this.virtualDataSource.sortingDataAccessor = (item: T, property: string) => {
            const column = this.gridColumns.find((col) => col.columnDef === property);
            if (column && column.sortProperty) {
                return (item as any)[column.sortProperty];
            }
            return (item as any)[property];
        };
    }

    ngAfterViewInit(): void {
        this.initializeEventSubscriptions();
    }

    /**
     * Initialisiert die Datenquelle des Grids.
     * Wenn overrideDataSource gesetzt ist, werden die Daten direkt verwendet.
     * Ansonsten werden die Daten über die loadData() Methode geladen.
     */
    private initializeData(): void {
        if (this.overrideDataSource && this.overrideDataSource.length > 0) {
            // Datenquelle vorhanden, Daten direkt setzen ohne Backend-Request
            this.virtualDataSource.data = [...this.overrideDataSource];
            this.initializeIdToIndexMap();
            this.loading = false;
        } else if (this.overrideDataSource && this.overrideDataSource.length === 0) {
            // Keine Daten vorhanden, Grid leeren
            this.virtualDataSource.data = [];
        } else if (!this.overrideDataSource) {
            // Keine Daten vorhanden, Daten laden
            this.loadData();
        }

        // Filterfunktion setzen (für die Suche)
        this.setCustomFilterPredicate();
        this.cdr.markForCheck();
    }

    // Daten laden (vom Backend)
    private loadData(): void {
        if (this.loading) {
            return;
        }

        this.loading = true;

        // Grid-Optionen
        const gridOptions: GridNewOptions = {
            // Alle Daten sollen geladen werden, da Pagination nicht mehr verwendet wird wegen Virtual Scrolling
            optionalBackendValues: {loadAll: true},
            regionsfilter: this.gridRegionsFilter,
            filter: this.gridFilter?.formular,
        };

        this.gridNewService.loadData(this.gridDataBackendUrl, gridOptions).subscribe({
            next: (data: T[]) => {
                // Geladene Daten mit den Tabellendaten verknüpfen
                this.virtualDataSource.data = [...this.virtualDataSource.data, ...data];

                // Map initialisieren
                this.initializeIdToIndexMap();
            },
            error: (error) => {
                console.error('Error loading grid data', error);
                this.loading = false;
            },
            complete: () => {
                this.loading = false;
                this.cdr.markForCheck();
            },
        });
    }

    // Grid zurücksetzen und Daten laden
    resetAndReloadGrid(): void {
        if (this.initialized) {
            this.resetGrid();
            this.initializeData();
        }
    }

    // Grid leeren
    resetGrid(): void {
        this.virtualDataSource.data = [];
        this.checkboxSelection.clear();
        this.cdr.markForCheck();
    }

    /**
     * Datenquelle filtern (Suche)
     * Wenn mindestens 3 Zeichen eingegeben wurden, wird die Suche gestartet
     * @param {string} searchInput - Suchbegriff
     */
    applyFilter(searchInput: string) {
        // Ab 3 Zeichen suchen
        if (searchInput.length > 2) {
            this.virtualDataSource.filter = searchInput.trim().toLowerCase();
            this.searchActive = true;
        } else if (searchInput.length <= 2) {
            // Suchergebnisse leeren, wenn die Eingabe gelöscht wurde
            this.virtualDataSource.filter = '';
            this.searchActive = false;
        }

        this.checkboxSelection.clear();
        this.cdr.markForCheck();
    }

    /**
     * Filterfunktion für die Tabelle (Suche)
     */
    private setCustomFilterPredicate(): void {
        this.virtualDataSource.filterPredicate = (data: T, filter: string): boolean => {
            // Suchbegriff bereinigen
            const transformedFilter = filter.trim().toLowerCase();
            // Überprüfen, ob einer der angezeigten Spalten den Suchbegriff enthält
            return this.displayedColumns.some((key) => {
                const column = this.gridColumns.find((col) => col.columnDef === key);
                let value: string;
                if (column && column.cell) {
                    // Wert aus der Zelle extrahieren, falls vorhanden
                    value = column.cell(data);
                } else {
                    // Andernfalls den Wert direkt aus den Daten lesen
                    value = data[key];
                }

                return value && value.toString().toLowerCase().includes(transformedFilter);
            });
        };
    }

    // Navigation zur Detailseite, falls eine Router-Link-Funktion definiert ist
    navigateTo(row: T, column: TableColumn<T>) {
        if (column.routerLinkFn) {
            const link = column.routerLinkFn(row);
            this.router.navigate(link);
        }
    }

    private initializeIdToIndexMap(): void {
        this.virtualDataSource.data.forEach((item, index) => {
            this.idToIndexMap.set(item.id, index);
        });
    }

    private updateIdToIndexMap(id: number | string, index: number): void {
        this.idToIndexMap.set(id, index);
    }

    // Neue Daten zur Datenquelle hinzufügen
    addEntityToDataSource(newEntity: T): void {
        this.virtualDataSource.data = [...this.virtualDataSource.data, newEntity];
        this.updateIdToIndexMap(newEntity.id, this.virtualDataSource.data.length - 1);
        this.cdr.markForCheck();
    }

    removeEntitiesFromDataSource(entities: T[]): void {
        if (!entities || entities.length === 0) {
            return; // Nichts zu löschen
        }

        // IDs der zu löschenden Entitäten holen
        const entityIdsToRemove = new Set(entities.map((entity) => entity.id));

        // Die zu löschenden Entitäten aus der Datenquelle entfernen
        this.virtualDataSource.data = this.virtualDataSource.data.filter((entity) => !entityIdsToRemove.has(entity.id));

        // Index Map aktualisieren
        this.initializeIdToIndexMap();

        // Checkbox Selection leeren - TODO: raus wenn mehrere gelöscht werden können
        this.checkboxSelection.clear();

        this.cdr.markForCheck();
    }

    /*
     * Auf Drop-Event reagieren und die Spalten neu anordnen
     */
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    }

    /**
     * Prüft, ob alle sichtbaren (gefilterten) Zeilen selektiert sind.
     * Anstatt alle Daten (this.virtualDataSource.data) zu prüfen,
     * nehmen wir nur die gefilterten Daten (this.virtualDataSource.filteredData).
     * @returns {boolean} true, wenn alle Zeilen selektiert wurden
     */
    isAllSelected(): boolean {
        const visibleData = this.virtualDataSource.filteredData;
        const numSelected = this.checkboxSelection.selected.length;
        const numVisibleRows = visibleData.length;
        return numSelected === numVisibleRows && numVisibleRows > 0;
    }

    /**
     * Selektiert oder Deselektiert alle sichtbaren (gefilterten) Zeilen.
     * Falls gefiltert bzw. gesucht wird, nur die aktuell angezeigten Zeilen selektieren.
     * Falls nicht gefiltert wird (searchActive = false), alle Daten auswählen.
     */
    toggleAllRows(): void {
        const visibleData = this.virtualDataSource.filteredData;

        if (this.isAllSelected()) {
            // Wenn schon alle ausgewählt sind, alles abwählen
            this.checkboxSelection.clear();
        } else {
            // Alle sichtbaren (gefilterten) Zeilen auswählen
            this.checkboxSelection.clear();
            this.checkboxSelection.select(...visibleData);
        }

        this.gridNewService.checkboxSelectionChanged(this.gridName, this.checkboxSelection.selected);
        this.cdr.markForCheck();
    }

    /**
     * Klick auf eine Checkbox im Grid
     * @param {T} row - Datenzeile
     */
    checkboxToggle(row: T): void {
        this.checkboxSelection.toggle(row);
        this.gridNewService.checkboxSelectionChanged(this.gridName, this.checkboxSelection.selected, row);
    }

    // Zeile wird angeklickt
    handleRowClick(row: T): void {
        this.gridNewService.selectionChanged(this.gridName, row);
    }

    /**
     * Aktualisiert die anzuzeigenden Spalten, indem die Checkbox-Spalte
     * an erster Stelle hinzugefügt wird, wenn enableCheckboxSelection true ist.
     * Ansonsten wird die Liste der anzuzeigenden Spalten unverändert übernommen.
     */
    private updateDisplayedColumns(): void {
        this.#displayedColumns = [...this.#originalDisplayedColumns];
        this.cdr.markForCheck();
    }

    /**
     * Listentry-Daten wurden geladen
     * @param {string} listname - Name der Liste
     * @param {any} storageData - Daten aus dem Storage
     */
    onGetListentriesFromStorage(listname: string, storageData: any): void {
        if (!storageData) {
            return;
        }

        // Initialisieren des Arrays für den Listennamen, falls es nicht existiert
        if (!this.lists[listname]) {
            this.lists[listname] = [];
        }

        // Jedes listentry in ein SelectData-Objekt umwandeln
        for (const obj of storageData) {
            const selectData: SelectData = {
                id: obj.list_key,
                label: obj.list_value,
                data: obj.list_data,
            };

            // Wenn data ein JSON-String ist, parsen
            if (typeof selectData.data === 'string') {
                try {
                    selectData.data = JSON.parse(selectData.data);
                } catch (error) {
                    console.error(`Error parsing data for list entry with id ${selectData.id}:`, error);
                    selectData.data = {};
                }
            }

            // Zum Array hinzufügen
            this.lists[listname].push(selectData);
        }

        this.cdr.markForCheck();
    }

    /**
     * Die Listentries für Grideinträge mit Listen aus der IndexedDB laden.
     * @returns {Promise<void>[]} Array mit Promises
     */
    initializeListentries(): Promise<void>[] {
        const allPromises: Promise<void>[] = [];

        for (const column of this.gridColumns) {
            const listNames = Array.isArray(column['listName']) ? column['listName'] : [column['listName']];

            for (const listName of listNames) {
                if (!listName) continue; // Überspringen, wenn listName undefined oder null ist
                const promise = this.storageService
                    .getItem('listentries|' + listName)
                    .then((val) => this.onGetListentriesFromStorage(listName, val));
                allPromises.push(promise);
            }
        }

        return allPromises;
    }

    /**
     * @description Die Listentries für Grideinträge mit Listen aus der IndexedDB laden
     * @returns {Promise<void>} Promise
     * TODO: Vom alten grid kopiert, noch prüfen
     */
    initializeCharacteristicsFormatTypes(): Promise<void> {
        const promise = this.storageService.getItem('listentries|characteristicsFormatType');

        return promise.then((values: any) => {
            if (values !== null && typeof values !== 'undefined') {
                values.forEach((listentry: Listentry) => {
                    // eslint-disable-next-line no-param-reassign
                    listentry.list_data = JSON.parse(listentry.list_data);
                    this.numberFormatTypes.push(listentry);
                });
            }
        });
    }

    /**
     * Wird aufgerufen, wenn der InitService fertig ist, sodass Kennzeichen und Listentries schon im Storage liegen
     */
    onAllInitialized() {
        // Die Daten asynchron aus dem Storage in die Komponente laden
        Promise.all(this.initializeListentries().concat(this.initializeCharacteristicsFormatTypes())).then(() => {
            // Flag setzen, Grid ist fertig initialisiert
            this.initialized = true;
            this.initializeData();
        });
    }

    /**
     * Löscht die aktuelle Auswahl der Checkboxen
     */
    private clearCheckboxSelection() {
        this.checkboxSelection.clear();
    }

    /**
     * Zeigt eine Snack-Bar mit der angegebenen Meldung an
     * @param {string} text - Der Text der Meldung.
     */
    private showSnackbar(text: string = '') {
        this.snackBar.openFromComponent(SnackbarDefaultComponent, {
            panelClass: 'cw-grid-source-snackbar',
            data: {label: text},
            duration: 2000,
        });
    }

    /*
     * Temporärer fix für Kontakte
     * beim anlegen / bearbeiten eines kontakts kommt contact_start_date und contact_end_date zurück
     * die grid spalte greift aber auf contact_start bzw. contact_end zu, um die Daten anzuzeigen
     *
     * Diese wären erst nach einem reload verfügbar, weshalb man diese Daten hier vorbereiten muss
     *
     * TODO: raus, sobald die Daten korrekt ankommen / verbessern
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prepareContactDates(entity: any) {
        const updatedEntity = entity;

        if (updatedEntity && updatedEntity.contact_start_date) {
            updatedEntity.contact_start = updatedEntity.contact_start_date;
        }

        if (updatedEntity && updatedEntity.contact_end_date) {
            updatedEntity.contact_end = updatedEntity.contact_end_date;
        }

        return updatedEntity;
    }
}
